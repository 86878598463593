$text-color: white;
$focus-color: rgb(40, 215, 159);

#contact-info {
  display: flex;
  flex-direction: column;
  height: 100vh;

  #contact-container {
    margin: auto;
    margin-top: 20vh;

    h1 {
      color: $focus-color;
      font-size: 5em;
    }

    a {
      color: $text-color;
      font-size: 2em;
      display: flex;
      align-items: center;
      padding: 0.25em;
  
      &:hover {
        color: $focus-color;
  
        .MuiSvgIcon-root {
          color: $focus-color;
        }
      }
    }
  
    a > .MuiSvgIcon-root {
      width: 2em;
      height: 2em;
      padding-right: 1em;
      color: $text-color;
    }
  }
}

// Responsive viewport adjustments
@media only screen and (max-width: 725px) {
  #contact-info > #contact-container {
    margin-left: 8vw !important;

    a {
      font-size: 4vw;
    }
  }
}

@media only screen and (max-width: 900px) {
  #contact-info > #contact-container {
    margin-left: 8vw;

    h1 {
      font-size: 3.5em;
    }
  }
}