$background-color: #0d121a;
$text-color: white;
$highlight-color: rgb(162, 216, 162);
$focus-color: rgb(40, 215, 159);

#bio {
  display: grid;
  grid-template-columns: 1fr 2fr;

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    padding: 6vw;
  }

  .summary {
    padding: 0.5em;
    margin-top: 0;
    margin-bottom: 1vh;
    width: 100%;
    font-size: 2.5vw;

    span {
      color: $focus-color;
    }
  }

  .home-bttns {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 2em;
      text-align: center;
      background-color: $background-color;
      color: $highlight-color;
      border-color: $focus-color;
      margin-bottom: 1em;
      padding: 0.5em;
      display: flex;
      align-items: center;

      &:hover {
        color: $focus-color;
      }

      .MuiSvgIcon-root {
        color: $focus-color;
        width: 2.5em;
        height: 2.5em;
      }
    }
  }

  .stack {
    text-align: center;
    border-top: 1px solid $text-color;
    border-bottom: 1px solid $text-color;
  }

  #skills {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0;
    font-size: 1.5vw;
    grid-gap: 0;

    .container {
      .tech-title {
        color: $highlight-color;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      p {
        margin: 0;
      }
    }
  }
}

#bio-img-container {
  margin-top: 12em !important;
  margin-left: 5vw;
  border-radius: 50%;
  width: 30vw;
  height: 30vw;
  border: 2px solid $focus-color;
  position: fixed;
  
  span > #bio-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -1vw -4vw;
    border-radius: 50%;
    background-color: $background-color;
  }
}

// Responsive viewport adjustments
@media only screen and (max-width: 780px) {
  #bio {
    grid-template-columns: 1fr;
    margin-top: 20em;

    section {
      .summary {
        font-size: 1.5em;
        margin-bottom: 1vh;
        width: 90%;
      }

      .stack {
        margin-left: 6vw;
      }
    }

    #skills {
      font-size: 1em;
      margin-bottom: 5vh;
    }
  }

  #bio-img-container {
    position: absolute;
    margin-top: 21vh;
    margin-right: 0;
    width: 17em;
    height: 17em;
    left: 45%;
    transform: translate(-50%, -50%);

    #bio-profile-pic {
      object-position: -1em -2em !important;
    }
  }  
}

@media only screen and (max-width: 1200px) {
  #bio {
    .summary {
      margin-right: 1.5em;
    }
    .home-bttns > a {
      font-size: 1.5em;
    }
  }
}