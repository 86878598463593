$background-color: #0d121a;
$contrast-color: #273442;
$text-color: white;
$focus-color: rgb(40, 215, 159);
$font: 'Montserrat', sans-serif;

nav {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 1;

  #left-nav {
    margin-left: 2vw;
    display: flex;
    align-items: center;

    .logo2 {
      img {
        width: 5em;
        filter: grayscale(100);

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }

  #right-nav {
    margin-right: 2vw;

    .nav-item {
      font-family: $font;
      font-size: 1.5em;
      color: $text-color;
      text-decoration: none;
      margin-left: 2em;
      
      &:hover {
        color: $focus-color;
      }
    }
    .is-selected {
      color: $focus-color;
    }
  }
}

// Responsive view for mobile
@media only screen and (max-width: 780px) {
  nav {
    bottom: 0 !important;
    z-index: 2;
    background-color: $contrast-color !important;
    height: 7vh;

    #left-nav {
      display: none;
    }

    #right-nav {
      margin: auto;
      margin-top: 1vh;
      margin-bottom: 1vh;
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      .nav-item {
        font-size: 1em;
        margin-left: 0;
      }
    }
  }
}