$text-color: white;
$highlight-color: rgb(162, 216, 162);
$focus-color: rgb(40, 215, 159);

#resume {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  #cv {
    height: 85vh;
    transition: height 2s;
    margin-top: 10vh;

    &:hover {
      height: 125vh;
    }
  }

    .cv-bttn {
      width: auto;
      font-size: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
  
      .MuiSvgIcon-root {
        margin-left: 1em;
        width: 2em;
        height: 2em;
      }
    }

    #download {
      color: $focus-color;
      outline: none;
    }
    #canva {
      color: $text-color;
      outline: none;
    }
}

// Responsive viewport adjustments
@media only screen and (max-width: 780px) {
  #resume { 
    max-height: 100vh !important;
  }
  #cv {
    height: 55vh !important;
    margin-top: 5vh !important;
    margin-bottom: 18vh !important;

    &:hover {
      height: 55vh !important;
    }
  }
  .cv-bttn {
    font-size: 1em !important;
    bottom: 0 !important;
  }
  #canva {
    margin-bottom: 8vh;
    margin-right: 0 !important;
  }
  #download {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1050px) {
  #resume {
    #cv {
      height: 65vh;
      margin-left: 0.5em;
      margin-right: 0.5em;
  
      &:hover {
        height: 85vh;
      }
    }
    .cv-bttn {
      position: absolute;
      bottom: -4em;
    }
    #canva {
      margin-right: 15em;
    }
    #download {
      margin-left: 15em;
    }
  }
}

@media only screen and (max-width: 1450px) {
  #resume > #cv:hover {
    height: 100vh;
  }
  .cv-bttn {
    font-size: 1.25em;

    .MuiSvgIcon-root {
      margin-left: 0;
    }
  }
}