$background-color: #0d121a;
$text-color: white;
$highlight-color: rgb(162, 216, 162);
$focus-color: rgb(40, 215, 159);

#projects {
  margin-top: 10vh;

  #project-tl {
    display: flex;
    flex-direction: row;
    background-color: $background-color;
  
    .tl-item {
      width: 100%;
      height: 20vh;
      min-height: 200px;
  
      &:before {
        background: linear-gradient(to left, $highlight-color, $background-color);
        transform: translate3d(0, 0, 0) translateX(-50%);
      }
  
      &:hover {
        width: 100% !important;
        height: 100% !important;
  
        &:before {
          opacity: 1;
          transform: translate3d(0, 0, 0) translateX(0);
          transition: opacity 1s ease, transform 1s ease 0.25s;
        }
        .line-container > .text-container {
          height: min-content;
      
          .tl-content {
            position: initial;
            opacity: 1;
            transform: translateX(0);
            transition: all 0.75s ease 0.5s;
          }
        }   
        .line-container > .project-img-container > span > .project-img {
          opacity: 0.9 !important;
        }
        .tl-bg {
          .tl-bg-img {
            opacity: 0 !important;
            transition: 1s !important;
          }
        }
        .project-icons {
          margin-bottom: 2vh;
        }
      }
      .line-container {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        align-items: center;

        .text-container {
          height: min-content;
          margin-left: 8vw;
          padding-right: 2vw;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .tl-header {
            top: 0;
            position: relative;
            margin-bottom: 0;
            font-size: 2.5em;
            color: $focus-color;
            border-top: none;
            border-bottom: none;
            text-align: start;
          }
          .tl-date {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 0.4em;
            padding: 0.5em;
            color: white;
            z-index: 1;
            border-top: 1px solid $highlight-color;
            border-bottom: 1px solid $highlight-color;
            width: 200px;
            text-align: center;
            opacity: 1;
          }
          .responsive-date {
            visibility: hidden;
            padding: 0;
            color: white;
            z-index: 2;
            font-size: 0;
            padding: 0;
            border-top: 1px solid $highlight-color;
            border-bottom: 1px solid $highlight-color;
            width: 0;
            height: 0;
            text-align: center;
            opacity: 1;
          }
          .tl-content {
            transform: translate3d(0, 0, 0) translateX(0);
            -webkit-transform: translate3d(0, 0, 0) translateX(0);;
            text-align: start;
            font-size: 1.25em;

            p {
              margin-left: 0;
              text-align: start;
            }
            .project-icons {
              display: flex;
              align-items: center;

              a {
                margin-right: 1em;
                font-size: 1em;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center; 
              }
              .bttn-stretch.bttn-success {
                color: $text-color;
                &:hover {
                  color: $focus-color;
                  .MuiSvgIcon-root {
                    color: $focus-color;
                  }
                }
              }
              img{
                width: 2em;
                height: 2em;
                padding-left: 1em;
              }
              .MuiSvgIcon-root {
                width: 2em;
                height: 2em;
                color: $text-color;
                padding-left: 1em;
              }
              .host-bttn {
                margin-left: 4em;
              }
            }
          }
        }
        .project-img-container {
          width: 100%;
          height: 100%;

          .project-img {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 2s;
          }
        }
      }
      .tl-bg > .tl-bg-img {
        opacity: 0.5;
        transition: opacity 2s;
      }
    }
  }
}

@media (max-width: 780px) {
  #projects {
    margin-top: 0 !important;
  }

  #projects > #project-tl > .tl-item {
    .line-container {
      grid-template-columns: 1fr !important;

      .text-container {
        margin-left: 0;
        padding-left: 2vw;

        .tl-header {
          text-align: center;
        } 
        .tl-content > p {
          margin-left: 5%;
          margin-right: 5%;
        } 
        .responsive-date {
          margin: auto;
          width: 75% !important;
        }
      }
    }
  }

  #projects > #project-tl > .tl-item {
    &:before {
      background: linear-gradient(to top, $highlight-color, $background-color) !important;
    }
  }
  .project-icons { 
    justify-content: center !important;
    align-items: center !important;
    a {
      margin-right: 0 !important;
    }
    .host-bttn {
      padding-top: 0.25em !important;
    }
    .MuiSvgIcon-root {
      width: 1.25em !important;
      height: 1.25em !important;
    }
  }
  .tl-content {
    font-size: 1em !important;
  }
}

@media (max-width: 1100px) {
  #projects > #project-tl > .tl-item > .line-container {
    grid-template-columns: 1fr 1fr;
  }
  .project-icons { 
    flex-direction: column;

    .host-bttn {
      margin-left: 0 !important;
      padding-top: 1em;
    }
  }
  .project-img-container {
    .project-img {
      object-fit: contain;
    }
  }
  .tl-date {
    display: none;
  }
  .responsive-date {
    visibility: visible !important;
    font-size: 1em !important;
    padding: 0.5em !important;
    width: 250px !important;
    height: auto !important;
  }
}
