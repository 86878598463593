$background-color: #0d121a;
$text-color: white;
$highlight-color: rgb(162, 216, 162);
$focus-color: rgb(40, 215, 159);

//* Styles inspired by and modified off of design by Jeff Glen https://codepen.io/jeffglenn/pen/KNYoKa/
#about-tl {
  display: flex;
  background-color: $background-color;

  &:hover {
    .tl-item {
      width: 23.3333%;
    }
  }
}

.tl-item {
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 25%;
  height: 100vh;
  min-height: 600px;
  color: $text-color;
  overflow: hidden;
  transition: width 0.5s ease;
  
  &:before, &:after {
    transform: translate3d(0, 0, 0);
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
  }

  &:after {
    background: transparentize($background-color, 0.15);
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &:before {
    background: linear-gradient(to bottom, $highlight-color, $background-color, $background-color);
    opacity: 0;
    transform: translate3d(0, 0, 0) translateY(50%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &:hover {
    width: 35% !important;
    
    &:after {
      opacity: 0;
    }

    &:before {
      opacity: 1;
      transform: translate3d(0, 0, 0) translateY(0);
      transition: opacity 1s ease, transform 1s ease 0.25s;
    }
    
    .tl-content {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.75s ease 0.5s;
    }

    .tl-bg {
      filter: grayscale(0);
    }
  }
}

.tl-content {
  transform: translate3d(0, 0, 0) translateY(25px);
  position: relative;
  z-index: 1;
  top: 55%;
  opacity: 0;
  
  h1 {
    margin-left: 5%;
    margin-right: 5%;
    text-transform: uppercase;
    color: $focus-color;
    font-size: 1.5em;
  }

  p {
    margin-left: 5%;
    margin-right: 5%;
  }

  .tl-date {
    border-top: 1px solid $highlight-color;
    border-bottom: 1px solid $highlight-color;
    margin-left: 25%;
    margin-right: 25%;
    padding: 0.5em;
    text-align: center;
  }
}

.tl-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  color: $focus-color;
  font-size: 2em;
  text-align: center;
  width: 100%;
}

.tl-bg {
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%; height: 100%;
  top: 0; left: 0;
  background-size: cover;
  background-position: center center;
  transition: filter 0.5s ease;
  opacity: 0.25;
  filter: grayscale(100%);

  .tl-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 780px) {
  #about-tl {
    display: grid;
    grid-template-columns: 1fr;

    &:hover {
      .tl-item {
        width: 100%;
      }
    }
  
    .tl-item {
      width: 100%;
      height: 25vh;
      min-height: 200px;

      &:before {
        background: linear-gradient(to top, $highlight-color, $background-color, $background-color);
        transform: translate3d(0, 0, 0) translateX(-50%);
      }

      &:hover {

       width: 100% !important;
       height: 100% !important;

       &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) translateX(0);
        transition: opacity 1s ease, transform 1s ease 0.25s;
      }
      }
    }

    .tl-header {
      position: relative;
      top: 10%;
    }

    .tl-content {
      top: 0%;

      .about-description {
        margin-bottom: 5em;
      }
    }
  }

}