$background-color: #0d121a;
$text-color: white;
$focus-color: rgb(40, 215, 159);
$font: 'Montserrat', sans-serif;

html {
  background-color: $background-color;
  color: $text-color;
  font-family: $font;
  max-height: 100%;
  scroll-behavior: smooth;

  body {
    background: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    overflow: auto;
  }

  #heading {
    position: absolute;
    margin-left: 8vw;
    margin-top: 30vh;
  }

  .transitions-item {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 5em;
    will-change: transform, opacity, height;
    cursor: pointer;
  }

  #view-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    height: 95vh;

    #view-bttn {
      z-index: 1;
      font-size: 4em;
      outline: none;
      text-decoration: none;
      color: #28d79f;
      opacity: 0;
      transition: opacity 2s;
      display: flex;
      align-items: center;
      top: 15vh;
      margin-left: 5vw;
  
      .MuiSvgIcon-root {
        padding-left: 1em;
        width: 3em;
        height: 3em;
      }
    }
    #logo1 {
      margin-bottom: 3em;
    }
  }

  .hidden {
    visibility: hidden;
    position: absolute;
  }

  .media-icons {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 2vw;
    top: 25vh;
    z-index: 1;
    
    a > .MuiSvgIcon-root {
      width: 1.5em;
      height: 1.5em;
      padding-top: 1em;
      color: $text-color;

      &:hover {
        color: $focus-color;
      }
    }
  }

  #landing-container {
    height: 100vh;
  }

  #footer {
    position: fixed;
    bottom: 2vh;
    width: 100%;
    text-decoration: none;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;

    .MuiSvgIcon-root {
      padding-left: 0.5em;
      width: 1em;
      height: 1em;
    }

    &:hover {
      color: $focus-color;

      .MuiSvgIcon-root {
        color: $focus-color;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  #view-bttn {
    margin-bottom: 250px !important;
  }
}

@media only screen and (max-height: 850px) {
  #logo1 {
    width: 25vh !important;
  }
}

@media only screen and (max-width: 825px) {
  #root {
    .media-icons {
      display: none;
    }
    #route-animations {
      display: flex;
      justify-content: center;
      max-width: 100%;
      overflow-x: hidden;
    }

    .hidden {
      visibility: visible;
      z-index: 1;
      margin-left: 3vw;
      margin-top: 2vw;

      img {
        width: 5em;
        filter: grayscale(100);
  
        &:hover {
          filter: grayscale(0);
        }
      }
      @media (max-width: 400px) {
        img {
          width: 4em;
        }
      }
    }
    .transitions-item {
      font-size: 3.5em !important;
    }
    #landing-container {
      max-height: 700px !important;
      overflow-y: hidden !important;
    }
    #heading {
      word-wrap: break-word !important;
      justify-items: center;
      align-items: center;
      align-content: center;
      margin-left: 0 !important;
      margin-top: 5vh !important;
    }
    #view-container {
      height: 80vh !important;

      #logo1 {
        position: fixed !important;
        width: 35vh !important;
        bottom: 2em !important;
        margin-bottom: 0 !important;
      }
      #view-bttn {
        margin-bottom: 12em;

        .MuiSvgIcon-root {
          padding-left: 0 !important;

          width: 2em !important;
          height: 2em !important;
        }
      }
    }
    #footer {
      display: none;
    }
  }
}

@media only screen and (max-width: 1150px) {
  #landing-container {
    display: flex;
    justify-content: center;
  }
  #heading {
    display: flex;
    flex-direction: column;
    margin-top: 15vh !important;
  }
  #view-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 20vh;

    #logo1 {
      margin-left: 0 !important;
    }
    #view-bttn {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0 !important;
    }
  }
}

@media only screen and (max-width: 1275px) {
  #view-container > #logo1 {
    width: 25em;
    margin-left: 5vw;
  }
  #heading {
    margin-left: 4vw;
  }
}

@media only screen and (max-width: 1500px) {
  .transitions-item {
    font-size: 4em !important;
  }
  #view-container {
    #view-bttn {
      font-size: 2.25em !important;
    }
  }
}
